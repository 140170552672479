@import "_default.scss_include-mixins";

footer {

	float:left;
	position:relative;
	width:100%;
	padding:80px;
	padding-top:40px;
	background-color:#F1F1F1;

	h2 {
		font-family:'Suez One' !important;
	}

	p {

		@include md {
			font-size: 3vw !important;
			line-height: 5vw !important;
		}
	}

	.denktank {
		position:absolute;
		//right:-15vw;
		right:5vw;
		top:-5vw;
		width:14vw;
		height:14vw;
		background-image:url('../img/static/denktank.png');
		background-size:cover;
		background-repeat:no-repeat;
		animation: rotation 15s infinite linear;

			@include md {
				width:27vw;
				height:27vw;
			}

		.inside {
			position:relative;
			display:inline-block;
			top:50%;
			left:50%;
			transform:translate(-50%,-50%);
			width:100%;
			height:100%;

			.image-person {
			position:absolute;
			top:50%;
			left:50%;
			transform:translate(-50%,-50%);
			width:2.8vw;
			height:2.8vw;
			background-color:#ccc;
			border-radius:50%;
			background-size:cover;
			background-repeat:no-repeat;
			background-position:center;
			animation: rotation-backwards 15s infinite linear;

			@include md {
				width:5vw;
				height:5vw;
			}

			&.aleks {
				margin-left: -10%;
				margin-top: -34%;
				background-image:url('../img/static/aleks.jpg');
			}
			&.rogier {
				margin-left: -30%;
				margin-top: -22%;
				background-image:url('../img/static/rogier.jpg');
			}
			&.jonathan {
				margin-left: -30%;
				margin-top: 1%;
				background-image:url('../img/static/jonathan.jpg');
			}
			&.reamon {
			   margin-left: -9%;
			   margin-top: 14%;
			   background-image:url('../img/static/reamon.jpg');
			}
			&.lizzy {
			   	margin-left: 11%;
				margin-top: 1%;
			    background-image:url('../img/static/lizzy.jpg');
			}
			&.jasper {
			   	margin-left: 11%;
				margin-top: -22%;
			    background-image:url('../img/static/jasper.jpg');
			}

		}

		@keyframes rotation {
		  from {
		    transform: rotate(0deg);
		  }
		  to {
		    transform: rotate(359deg);
		  }
		}

		@keyframes rotation-backwards {
		  from {
		  	transform: rotate(359deg);
		  }
		  to {
		    transform: rotate(0deg);
		  }
		}

		}

	}

	.buttons {
		float:left;
		width:100%;
		margin-top:30px;

		a {
			display:inline-block;
			position:relative;
			margin:10px;
			margin-top:0;
			margin-bottom:0;

			&.icon-2 {
				margin-top:-2px !important;
			}

			svg {
				opacity:0.5;
			}

			&:hover svg {
				opacity:0.8;
			}

		}

	}
	
}