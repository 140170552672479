@import "_default.scss_include-mixins";

body {
	font-family:'IBM Plex Serif', serif;
	background-color:#fff;
	overflow-x:hidden;
}

/*
Selection
*/
::selection {
	background:#000;
	color:#fff;
}


h1,h2 {
	font-weight:400;
}

/*
Link
*/
a.bottomline {

	text-decoration:none;
	//width: calc(100%);
	background-image: linear-gradient(transparent calc(100% - 1px), white 1px);
	background-repeat: no-repeat;
	background-size: 0% 100%;
	transition: background-size 0.5s;

	&.color-black {
		background-image: linear-gradient(transparent calc(100% - 1px), black 1px);
	}
	
	&:hover {
	    background-size: 100% 100%;
	}

}

/*
Smooth parallax
*/
.js-fade-inonscroll {
	opacity:0; 
	margin-top:200px;

	@include sm {
		margin-top:100px;
	}

}