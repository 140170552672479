@import "_default.scss_include-mixins";

.page.content--home {

	.hero {
		float:left;
		width:100%;
		margin-top:6.5vw;

		@include md {
			margin-top:130px;
		}

		h1 {
			display:inline-block;
			font-family:'Suez One';
			font-size:7vw;
		
			padding:0;
			margin:0;
			color:$color-darkblue;

			@include md {
				font-size:8.5vw;
			}

		}

		.card {
			float:left;
			width:100%;
			margin-top:3vw;
			text-align:center;
			cursor:default; /* for now! */

			@include md {
				margin-top:7vw;
			}
			
			.inside {
				display:inline-block;
				width:100%;
				padding:20px;
				padding-bottom:0;
				padding-top:0;
				text-align:left;
				text-decoration: none;

				@include md {
					text-align:center;
				}

				h2 {
					font-family:'Suez One';
					font-weight:bold;
					font-size:1.6vw;
					line-height:2vw;
					margin:0;
					padding:0;
					color:#000;

					font-weight:400;

					@include md {
						font-size:5vw;
						line-height:6vw;
					}
				}

				p {
					font-family:'IBM Plex Sans';
					font-size:1.3vw;
					line-height:2.1vw;
					font-weight:300;
					color:#000;
					text-decoration: none;

					@include md {
						font-size:5vw;
						line-height:7.5vw;
					}
				}

				span {
					font-family:'IBM Plex Sans';
					font-size:20px;
					line-height:30px;
					font-weight:300;
					color:#000;
					text-decoration: underline;
				}

			}

		}

	}

	.projecten {
		float:left;
		width:100%;
		margin-top:3vw;
		padding:7vw;
		padding-top:0;

		.project-item {

			.absolute {
				position:relative;
				float:left;
				width:100%;
				padding-top:50%;
				background-color:#000;
				border-radius:10px 10px 0 0;
				overflow:hidden;

				&.round {
					border-radius:10px;
				}
			}

			&:hover .image-overlay {
				opacity:0.60;			
			}
			&:hover .text-overlay .text-overlay-inside h2 {
				// text-shadow:0 0 10px rgba(0,0,0,0.1);
			}

			.image {
				position:absolute;
				top:0;
				left:0;
				width:100%;
				height:100%;
				background-size:cover;
				background-repeat:no-repeat;
				background-position:center;
				z-index:2;
			}

			.video {
				position:absolute;
				top:0;
				left:0;
				width:100%;
				height:100%;
				z-index:2;

				&.opacity-low {
					opacity:0.5;
				}
				&.opacity-high {
					opacity:1;
				}

				video {
					width:100%;
					height:100%;
					object-fit:cover;
				}
			}

			.image-overlay {
				position:absolute;
				top:0;
				left:0;
				width:100%;
				height:100%;
				background-color:#000;
				opacity:0.80;
				z-index:3;
				transition:all 0.5s ease;
			}

			.text-overlay {
				position:absolute;
				top:0;
				left:0;
				width:100%;
				height:100%;
				z-index:4;

				.text-overlay-inside {

					position:absolute;
					top:50%;
					left:50%;
					transform:translate(-50%,-50%);
					width:100%;

					h2 {
						float:left;
						width:100%;
						margin:0;
						padding:0;
						font-family:'Suez One';
						color:#fff;
						font-size:3vw;
						text-shadow:0 20px 50px rgba(0,0,0,1);

						@include md {
							font-size:5vw;
							line-height:7vw;
						}
					}

					span.client {
						float:left;
						width:100%;
						margin:0;
						padding:0;
						margin-top:20px;
						font-family:'IBM Plex Sans';
						color:#fff;
						font-size:1.3vw;

							@include md {
								font-size:3vw;
							}

					}

				}

			}

			.done {
				float:left;
				width:100%;
				margin-bottom:30px;
				border-radius:0 0 10px 10px;
				background: rgb(2,0,36);
				background: linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(0,0,0,0.07464992833070727) 100%);
				padding:20px;
				font-family:'IBM Plex Sans',arial;
				font-size:1vw;
				color:#000;
				border-bottom:1px solid #e1e1e1;
				border-left:1px solid #e1e1e1;
				border-right:1px solid #e1e1e1;

				@include sm {
					font-size:12px;
					line-height:18px;
				}
			}

		}

	}

}