@import "_default.scss_include-mixins";

.mobile-menu {
	display:block;
	position:fixed;
	left:0;
	top:-9999px;
	width:100%;
	height:100%;
	background-color:$color-darkblue;
	z-index:9999;
	
	.scroller {
		position:relative;
		width:100vw;
		height:100vh;
	}

	.inside {
		position:absolute;
		top: 50%;
		left:50%;
		transform: translate(-50%,-50%);
		text-align:center;

		.break {
			float:left;
			width:100%;
			margin-top:10px;
		}
	
		a {
			float:left;
			width:100%;
			text-align:center;
			color:#fff;
			font-family: 'Helvetica', sans-serif;
			font-size:20px;
			line-height:30px;
			font-weight:bold;
			text-decoration:none;
			text-transform: uppercase;
		}

	}

}