@import "_default.scss_include-mixins";

.textblock-1 {

	float:left;
	width:100%;

	p,ul li,.p {

		&.ibmplex {
			font-family:'IBM Plex Sans';
		}

		padding:0;
		margin:0;
		font-size:1.3vw;
		line-height:2vw;
		color:#000;

		@include md {
			font-size:4.5vw;
			line-height:6.4vw;
		}

		a {
			color:#888;

			&:hover {
				color:#000;
			}
			
		}

	}

	h1 {
	   font-family:'Suez One';
	   font-size:7vw;
	   padding:0;
	   margin:0;
	   margin-top:1vw;
	   margin-bottom:0.5vw;
	   font-weight:normal;
	   color:#060d36;

	   	@include md {
			font-size:9vw;
		}
	}

	h2 {
	   font-family:'Suez One';
	   font-size:2.3vw;
	   padding:0;
	   margin:0;
	   margin-top:1vw;
	   margin-bottom:0.5vw;
	   font-weight:normal;
	   color:#000;

	   	@include md {
			font-size:20px;
			line-height:20px;
		}

	}

}

.textblock-2 {

	float:left;
	width:100%;

	p,ul li,.p {

		&.ibmplex {
			font-family:'IBM Plex Sans';
			font-weight:300;
		}

		padding:0;
		margin:0;
		font-size:1.2vw;
		line-height:2.0vw;
		color:#000;

		a {
			color:#888;

			&:hover {
				color:#000;
			}
			
		}

		@include md {
			font-size:4.5vw;
			line-height:8vw;
		}

	}
	
	h1 {
	   font-family:'Suez One';
	   font-size:3vw;
	   padding:0;
	   margin:0;
	   margin-top:1vw;
	   margin-bottom:0.5vw;
	   font-weight:normal;
	   color:#060d36;

	   	@include md {
			font-size:20px;
			line-height:20px;
		}

	}

	h2 {
	   font-family:'Suez One';
	   font-size:1.5vw;
	   padding:0;
	   margin:0;
	   margin-top:1vw;
	   margin-bottom:0.5vw;
	   font-weight:normal;
	   color:#000;

	   	@include md {
			font-size:7vw;
			line-height:9vw;
		}
	}

}