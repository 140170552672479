@import "_default.scss_include-mixins";	 

header.desktop {

	position:fixed;
	top:0;
	left:0;
	width:100%;
	padding-top:1vw;
	padding-bottom:1vw;
	text-align:center;
	z-index:1000;

	.inside {
		display:inline-block;
		width:90%;

		.logo {
			float:left;
			background-color:#fff;
			border-radius:50%;
			width:4.3vw;
			height:4.3vw;
			overflow:hidden;

			svg {
				width:4.3vw;
				height:4.3vw;

				.st0{ 
					fill:#F93500; 
				}
			}
		}

		nav {
			float:right;
			margin-top:0.6vw;

			ul {
				list-style-type: none;

				li {
					float:left;
					margin-left:20px;
					margin-right:20px;
				}
			}

			a {
				color:#888;
				font-family:'IBM Plex Sans';
				font-size:1.2vw;
				line-height:1.2vw;
				text-decoration: none;
				
				&:hover {
					border-bottom:2px solid rgba(0,0,0,0.1);
				}
				
				&.active {
					border-bottom:2px solid rgba(0,0,0,0.1);
				}

			}

		}

	}
	
}

header.mobile {

	.logo {

		position:fixed;
		right:80px;
		top:40px;
		width:61px;
		height:60px;
		background-color:#fff;
		border-radius:50%;
		z-index:20;
		transform:scale(0);

		@include sm {
			right:20px;
		}

		.st0{ 
			fill:#F93500; 
		}

		.icon {
			display:block;
		}

		.exit {
			display:none;
		}

		&.show {
			animation: show 0.3s cubic-bezier(0.175, 1, 0.320, 1.275) both;
		}
		&.gone {
			animation: hide 0.3s cubic-bezier(0.175, 1, 0.320, 1.275) both;
		}

	}

	/*
	Projet: Exit button
	*/
	.exit {

		position:fixed;
		right:80px;
		top:40px;
		width:61px;
		height:60px;
		background-color:#fff;
		border-radius:50%;
		z-index:20;
		transform:scale(0);
		box-shadow:0 0 30px rgba(0,0,0,0.2);
		cursor:pointer;

		@include sm {
			right:20px;
		}

		svg {
			position:absolute;
			top:50%;
			left:50%;
			transform: translate(-50%, -50%);
			width:24px;
			height:24px;
		}

		.st0{ 
			fill:#000; 
		}

		.icon {
			display:block;
		}

		&:hover {
			animation:rotate 3s linear infinite alternate;
		}

		&.show {
			animation: show 0.3s cubic-bezier(0.175, 1, 0.320, 1.275) both;
		}
		&.gone {
			animation: hide 0.3s cubic-bezier(0.175, 1, 0.320, 1.275) both;
		}

	}

	/*
	Menu: Button
	*/
	.menu-fixed {

		position:fixed;
		left:80px;
		top:0;
		transform:translateY(-100px);
		width:61px;
		height:60px;
		background-color:#fff;
		border-radius:50%;
		box-shadow:0 0 30px rgba(0,0,0,0.2);
		cursor:pointer;
		z-index:510;

		@include sm {
			left:20px;
		}

		.lines {
			position:relative;
			margin-top:21px;

			.line {
				position:absolute;
				top:0;
				left:12px;
				width:35px;
				height:2px;
				background-color:#000;

				&.line-1 {
					top:0px;
				}
				&.line-2 {
					top:8px;
				}
				&.line-3 {
					top:16px;
				}

			}
		}

	}

	.menu-fixed-overlay {
		position:fixed;
		top:-800px;
		left:-800px;
		width:500px;
		height:500px;
		border-radius:50%;
		background-color:#F93500;
		box-shadow:0 0 30px rgba(0,0,0,0.2);
		z-index:500;

		.inside {
			position:absolute;
			left:50%;
			top:50%;
			margin-top:85px;
			margin-left:50px;
			transform: translate(-50%, -50%);

			@include md {
				margin-left:90px;
			}

			ul {
				padding:0;
				margin:0;

				li {
					list-style-type: none;
					padding:0;
					margin:0;

					a {
						font-family:'Noto Sans JP',arial;
						font-size:24px;
						line-height:45px;
						color:#fff;

						@include md {
							font-size:22px;
							line-height:35px;
						}
					}

				}

			}
			

		}

	}
	
}

@keyframes show {
	0%	{
		transform: scale(0)
	}
	100%	{
		transform: scale(1)
	}
}

@keyframes hide {
	0%	{
		transform: scale(1)
	}
	100%	{
		transform: scale(0)
	}
}