@import "_default.scss_include-mixins";

.page.content--project {

	float:left;
	width:100%;
	margin-top:6.5vw;
	padding-bottom:6.5vw;

	@include md {
		margin-top:130px;
	}

	img {
		border-radius:10px;
	}

	.helper-video1 video {
		max-width:100%;
	}

	.width-98 {
		width:98%;
	}

	.row.display-flex {
	  display: flex;
	  flex-wrap: wrap;
	}
	.row.display-flex > [class*='col-'] {
	  display: flex;
	  flex-direction: column;
	}

	[class*='col-'] {

		@include md {
			margin-top:20px;
		}

	}

	.textblock-2.mobilemarge {
		@include md {
			margin-top:20px;
		}
	}

	.block {
		float:left;
		width:100%;
		margin-top:5vw;
		position:relative;

		&.no-bottom-margin {
			margin-bottom:0;
		}

		&.no-top-margin {
			margin-top:0;
		}

		.textblock-2 {

			&.middle {
				position:absolute;
				top:50%;
				left:50%;
				width:80%;
				transform:translate(-50%,-50%);

				@include md {
					position:relative;
					left:0;
					top:0;					
					transform:translate(0,0);

					width:100%;
					text-align:center;
					
				}
			}

		}

		.padding-image-right {
			padding-right:1.5vw;

			@include md {
				padding-right:0;
			}

		}
		.padding-image-left {
			padding-left:1.5vw;

			@include md {
				padding-left:0;
			}
		}

		&.widthpadding {
			padding-left:5vw;
			padding-right:5vw;
		}

		.image-bckgrnd {
			float:left;
			width:100%;
			height:100%;
			background-size:cover;

			&.position-right {
				background-position:left
			}

		}

		.video-border {
			float:left;
			width:100%;
			
			&.border {
				padding:20px;
				border:2px solid #ccc;
			}

		}

	}

}