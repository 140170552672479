@import "_default.scss_include-mixins";

.page.content--onsverhaal {

    float:left;
    width:100%;
    margin-top:6.5vw;
    padding-bottom:6.5vw;

      @include md {
        margin-top:130px;
      }

  // .row {
  //   display: -webkit-box;
  //   display: -webkit-flex;
  //   display: -ms-flexbox;
  //   display:         flex;
  //   flex-wrap: wrap;
  // }
  // .row > [class*='col-'] {
  //   display: flex;
  //   flex-direction: column;
  // }

  .relative {
    position:relative;

    @include md {
      position:inherit;
    }

    h2 {
      position:absolute;
      top:280px;
      left:0px;

      @include md {
       position:inherit;
       left:auto;
       top:auto;
      }

    }

  }

  .mt-40 {
    margin-top:6vw;
  }

  h2.rotate {
    transform:rotate(-90deg);

    @include md {
      transform:none;
    }

  }

   strong {
      font-family:'IBM Plex Sans';
      font-weight:400;
    }

  ul {
    list-style: none;
    margin:0;
    padding:0;

    &.row-2 {
      margin-top:40px;
    }

    li {
      font-size:1.2vw;
      line-height:2.3vw;

      @include md {
        font-size:4.5vw;
        line-height:6.4vw;
      }

    }

  }

  .buttons {
   float:left;
   margin-top:30px;
   
    .button {
      display:inline-block;
      font-size:1.2vw;
      line-height:2.3vw;
      width:100%;
      padding:0;
      padding:10px;
      padding-top:5px;
      padding-bottom:5px;
      margin-bottom:10px;

      @include sm {
        font-size:6vw;
        line-height:6.4vw;
        padding-top:20px;
        padding-bottom:20px;
        margin-bottom:20px;
      }

    }

  }

}