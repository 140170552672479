@import "_default.scss_include-mixins";

.page.content--overons {

    float:left;
    width:100%;
    margin-top:6.5vw;
    padding-bottom:6.5vw;

      @include md {
        margin-top:130px;
      }

  .team {
    padding-top:40px;
    padding-bottom:80px;

    @include sm {
      padding-bottom:20px;
    }

    p {
      padding:0;
      margin:0;
      margin-bottom:40px;
    }

     .team-item {
      float:left;
      position:relative;
      width:100%;
      padding-top:100%;
      background-image: linear-gradient(to bottom, #091d4c, #041a3d, #05152e, #05101f, #020710);
      margin-bottom:30px;
      border-radius:10px;

      &:hover .name {
        opacity:0;
      }

      &:hover .image {
        opacity:1;
      }


      .name {
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        z-index:10;
        transition: all 0.4s;
          
          h2 {
            font-family:'Noto Sans JP',arial;
            font-size:40px;
            text-align:center;
            color:#fff;
            font-weight:bold;
            padding:0;
            margin:0;

            @include md {
              font-size:12px;
            }

          }

          h3 {
            font-family:'Open Sans',arial;
            font-size:15px;
            text-align:center;
            color:#fff;
            font-weight:bold;
            padding:0;
            margin:0;
          }

      }

      .image {
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
        background-size:cover;
        background-repeat:no-repeat;
        background-position:center;
        opacity:0.2;
        transition: all 0.5s;
      }

    }

  }

}