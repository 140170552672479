@import "_default.scss_include-mixins";

.page.content--jouwverhaal {

    float:left;
    width:100%;
    margin-top:6.5vw;
    padding-bottom:6.5vw;

      @include md {
        margin-top:130px;
      }

}