@import "_default.scss_include-mixins";

.page.content--ditmakenweook {

    float:left;
    width:100%;
    margin-top:6.5vw;
    padding-bottom:6.5vw;

      @include md {
        margin-top:130px;
      }

      .container-fluid {
        padding-left:180px;
        padding-right:180px;
      }

      .tags {
        float:left;
        width:100%;
        text-align:center;

        .tag {
          display:inline-block;
          font-size:16px;
          color:#000;
          padding:10px;
          background-color:#e1e1e1;
          border-radius:5px;
          margin:5px;
          cursor:pointer;

          &:hover {
            transform:scale(1.1);
            background-color:#000;
            color:#fff;
          }
        }

      }

      .row {

         &.marge-top-5 {
            margin-top:5vw;
          }

          &.marge-top-10 {
            margin-top:10vw;
          }

      }

      .masonry {

        .block {
          float:left; 
          width:100%; 
          height:100%;
          margin-bottom:30px;

          img {
            // margin-top: auto;
            // margin-bottom: auto;
            max-width:100%;
            border-radius:20px;
            overflow:hidden;
          }

        }

      }

}