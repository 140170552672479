@import "_default.scss_include-mixins";

.cta {
	
	float:left;
	width:100%;
	margin-top:3vw;

	h2 {
		font-family:'IBM Plex Sans';

		@include md {
			font-size:7vw;
			line-height:8vw;
			margin-bottom:20px;
		}

	}

	.row-buttons {
		margin-top:2vw;
	}

	.button {
		margin-left:1.2vw;
		margin-right:1.2vw;

		@include md {
			margin-left:0;
			margin-right:0;
		}

	}

}